<template>
    <div class="News">
        <head-img desc="新闻中心" :imgUrl="newsBgUrl"></head-img>
        <my-menu ref="menu" :titleList="titleList"  :menuList="menuList" @changeTab="changeTab"></my-menu>
        <div class="news-content">
            <div class="content">
                <div class="news-list">
                    <div class="item" v-for="(item, i) in newsList" :key="i"  @click="goDetail(item.id)">
                        <div class="img" :class="{'bgcolor': item.showBg}">
                            <el-image class="img-news" :src="getImg(item.picUrl)" fit="cover"></el-image>
                            <div class="zhezhao" v-show="item.showBg">
                                <div class="title">
                                    {{item.matchName}}
                                </div>
                            </div>
                        </div>
                        <div class="title">
                            {{item.matchName}}
                        </div>
                        <div class="text">
                            <p>{{item.corpName}}</p>
                            <p>
                                <i class="el-icon-location"></i>
                                {{item.address}}
                            </p>
                        </div>
                        <div class="btn">
                            <span>查看详情</span>
                            <span><i class="el-icon-right"></i></span>
                        </div>
                    </div>
                </div>

                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="total"
                    @current-change="currentPage"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            page: {
                current: 1,
                size: 6,
            },
            model: {
                status: 1, 
                websiteFlag: 1
            },
            total: 0,
            active: 0,
            newsBgUrl: require('@/assets/img/home/lunbo6.jpg'),
            titleList: [
                {name: '赛事预告'},
                {name: '精彩瞬间'},
            ],
            menuList: [
                {name: '首页', path: '/'},
                {name: '新闻中心'},
            ],
            newsList: [],

        }
    },
    methods:{
        // onMouseover(i) {
        //     this.newsList[i].showBg = true
        //     console.log(this.newsList)
        // },
        // onMouseout(i) {
        //     this.newsList[i].showBg = false
        // },
        currentPage(data) {
            console.log(data)
        },
        changeTab(data) {
            this.active = data
            this.init()
        },
        goDetail(id){
            this.$router.push({path: '/news/detail', query:{
                id
            }})
        },
        init() {
            if(this.active === 0){
                this.getMatchList()
            }
            if(this.active === 1) {
                this.getPlayBack()
            }
        },
        //赛事预告
        getMatchList() {
            this.$store.commit('loading', '.News .news-content')
            this.$api.post('base/match/match-noticeForHomePage', {page: this.page, model: this.model}).then(res=>{
                //console.log(res)
                this.newsList = res.data.records
                this.newsList.map(ele=>{
                    ele.showBg = false
                })
                this.total = res.data.total
                //console.log(this.newsList)
            })
            .finally(()=>{
                this.$store.commit('loaded')
            })
        },
        //精彩瞬间
        getPlayBack() {
            this.$store.commit('loading', '.News .news-content')
            this.$api.post('base/match/match-playbackForHomePage', {page: this.page, model: this.model}).then(res=>{
                this.newsList = res.data.records
                this.newsList.map(ele=>{
                    ele.showBg = false
                })
                this.total = res.data.total
            })
            .finally(()=>{
                this.$store.commit('loaded')
            })
        }

    },
    mounted() {
        this.$refs.menu.setValue(true);
        this.init()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .News{
        .news-content{
            display: flex;
            justify-content: center;
            margin-top: 50px;
            .content{
                width: 1200px;
                .news-list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    .item{
                        width: 430px;
                        margin-right: 30px;
                        margin-bottom: 20px;
                        border: 1px solid #ededed;
                        &:hover{
                            cursor: pointer;
                        }
                        .img{
                            height: 230px;
                            &-news{
                                height: 230px;
                                width: 100%;
                            }
                            position: relative;
                            .zhezhao{
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: rgba(0, 0 ,0, .5);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .title{
                                    color: #fff;
                                    padding: 5px 0;
                                }
                            }
                        }
                        .title{
                            padding: 10px 20px;
                            font-size: 16px;
                            color: #333;
                        }
                        .text {
                            margin: 0 20px;
                            padding-bottom: 10px;
                            border-bottom: 1px solid #ededed;
                            p{
                                // width: 800px;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                                overflow: hidden;
                            }
                        }
                        .btn{
                            padding: 10px 20px;
                            display: flex;
                            justify-content: space-between;
                            i{
                                font-size: 20px;
                                &:hover{
                                    color: $main;
                                }
                            }
                        }
                        
                    }
                    .bgcolor{
                        img{
                            transition: all 0.3s;
                            transform: scale(1.1);
                            cursor: pointer;
                        }
                    }
                }
                .el-pagination{
                    margin: 20px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

</style>